





import Vue from 'vue';
import SiteLayout from '@/layouts/site-layout.vue';

export default Vue.extend({
  components: {
    SiteLayout,
  },
});
